<template>
  <div class="header-wrap">
    <img class="logo" src="@/assets/images/congting-logo-u.png" style="height: 40px;margin: 11px 35px; margin-left: 10px" alt="">
    <div class="header-right">
      <div class="phone" style="color: #eee; margin-right: 10px; margin-top: -2px">
        <span id="guideElement12" v-if="isTrial" class="go-to-client" @click="goToClient" v-loading="goToClientLoading" style="position:relative; left: -50px; background: #3472c7; padding: 10px 15px; border-radius: 8px; padding-left: 20px;font-size: 15px">
          <svg style="position: relative; top: 4px" t="1715075370619" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13599" width="20" height="20"><path d="M512 170.666667a42.666667 42.666667 0 0 1 42.666667 42.666666v597.333334a42.666667 42.666667 0 1 1-85.333334 0V213.333333a42.666667 42.666667 0 0 1 42.666667-42.666666z m213.333333 170.666666a42.666667 42.666667 0 0 1 42.666667 42.666667v426.666667a42.666667 42.666667 0 1 1-85.333333 0V384a42.666667 42.666667 0 0 1 42.666666-42.666667zM298.666667 512a42.666667 42.666667 0 0 1 42.666666 42.666667v256a42.666667 42.666667 0 1 1-85.333333 0v-256a42.666667 42.666667 0 0 1 42.666667-42.666667z" fill="#ffffff" p-id="13600"></path></svg>
          跳转客户端
        </span>
        <i class="el-icon-phone-outline" style="margin-left: -30px;font-size: 20px;position: absolute;margin-top: 20px"></i>
        <span class="num">400-618-7710</span>
<!--        <span v-else>400-618-7710</span>-->
      </div>
      <section class="operation">
        <div class="icons" style="display: grid; grid-template-columns: auto auto auto;">
          <div class="header-icon" @click.stop="showNotice = true;showExport = false" id="guideElement11">

            <i style="" class="iconfont icon-xiaoxi">
              <el-badge style="position: absolute; margin-top: -30px; margin-left: 10px" :value="count" :hidden="count==0?true:false"></el-badge>
            </i>

          </div>
          <div class="header-icon" @click.stop="showExportDialog()" id="guideElement10">
            <i style="" class="iconfont icon-xiazai"></i>
          </div>
          <div class="header-icon" id="guideElement9">
            <span v-if="isEnable" @click="changeProgect" style="margin-right: 10px">
                <i class="el-icon-menu" style="margin-right: 5px;font-size: 18px;position: relative;top: 1px;"></i>项目切换
            </span>
          </div>
        </div>
        <transition name="el-zoom-in-top">
          <notice @click.native.stop="showNotice = true;showExport = false" v-if="showNotice" @setRead="getCount" :noticeClose="noticeClose"></notice>
        </transition>
        <!-- <export-list @click.native.stop="showExport = true;showNotice = false" v-if="showExport" :exportClose="exportClose"></export-list> -->
      </section>
      <div style="display: flex;align-items: center;" @click="showMenu">
<!--        <img class="avatar" src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png" alt="">-->
        <div class="avatar">{{ cusName ? cusName.substring(0, 1) : '管' }}</div>
        <span class="nickname" style="overflow: hidden; text-align: center">{{ name.indexOf('@') !== -1 ? '试用账号' : name }}</span>
<!--        <span class="nickname" style="width: 64px; overflow: hidden; text-align: center">{{ name.indexOf('@') !== -1 ? '试用账号' : name }}</span>-->
        <transition name="el-zoom-in-top">
          <ul class="dropdown-menu" v-show="showAvatorMenu" @mouseleave="showAvatorMenu = false" :style="isEnable ? 'bottom: -120px' : 'bottom: -120px'">
            <li @click="resetPass" style=""><i class="el-icon-lock" style="margin-right: 5px"></i>修改密码</li>
              <li @click="bindWechat" style=""><i class="el-icon-bell" style="margin-right: 5px"></i>微信通知</li>
            <li @click="logout"><i class="el-icon-switch-button" style="margin-right: 5px"></i>退出登录</li>
          </ul>
        </transition>
      </div>
    </div>
    <el-dialog title="任务列表" :visible.sync="showExport" width="60%" @click.native.stop="showNotice = false">
      <el-table :data="tableData" border v-loading="loading" ref="table" height="330px" style="width: 100%">
        <el-table-column  :resizable="false" prop="alias" align="center" label="文件名称"></el-table-column>
        <el-table-column  :resizable="false" prop="state" align="center" width="160px" label="状态">
          <template scope="{ row }">
            <span v-if="row.state === 0">处理中</span>
            <span v-else-if="row.state === 1" style="color: #67C23A">已完成</span>
            <span v-else style="color: #F56C6C">发生错误</span>
          </template>
        </el-table-column>
        <el-table-column  :resizable="false" label="操作" align="center" width="100px">
          <template scope="{ row }">
              <el-button v-if="row.ownedFunction !== '批量修改媒体站点' && row.state === 1" type="text" @click.stop="handleFun (row.id)">下载</el-button>
              <el-button type="text" @click.stop="deleteFun (row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="list-bottom">
        <el-pagination :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="size" layout="total, sizes, prev, pager, next"
                       @size-change="sizeChange" @current-change="currentChange" :total="tableNum">
        </el-pagination>
      </section>
    </el-dialog>
    <el-dialog title="修改密码" width="30%" :visible.sync="dialogVisible">
      <el-form :model="form" label-width="80px" :rules="rules" ref="form">
        <el-form-item label="原密码" prop="password">
          <el-input v-model="form.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="form.newPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPassword">
          <el-input v-model="form.checkPassword" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancle">取消</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="绑定微信" width="400px" :visible.sync="bindWechatDialogVisible">
      <div style="text-align: center" v-loading="showQRLoading">
        <div v-show="!isBinded" style="font-size: 18px; line-height: 25px">请使用<span style="color: #05D166; font-weight: bold">微信</span>关注公众号<br/>并扫描下方二维码</div>
        <el-image v-show="!isBinded"
          style="width: 350px; height: 350px"
          :src="bindUrl"
          fit="fill"></el-image>
        <el-image v-show="isBinded"
                  style="width: 170px; height: 170px"
                  src="/bound-wechat.png"
                  fit="fill"></el-image>
        <div style="margin-top: 20px" v-show="isBinded">
          <el-button @click="doUnBindWechat" type="danger">解除当前微信绑定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="项目切换"
      :z-index="2004"
      :visible.sync="changeCusDialogVisible"
      top="30vh"
      width="30%"
      center>
<!--      <div style="margin-bottom: 20px">当前项目为<span style="color: #2D64B3; font-weight: bold">{{ this.cusName }}</span></div>-->
      <div style="overflow: auto">
        <div v-for="item in projectList"
             :key="item.id"
             style="width: calc(50% - 10px); margin: 5px; background-color: #F1F3F4; float: left; padding: 15px 0; overflow: hidden; cursor: pointer; text-align: center"
             @click="clickSwitchProject($event, item)"
             class="sw-project"
        >
          {{ item.customerName }}
        </div>
      </div>
<!--      <el-form :model="cusform">-->
<!--        <el-form-item>-->
<!--          &lt;!&ndash; <el-input v-model="form.project" placeholder="请输入您想搜索的项目"></el-input> &ndash;&gt;-->
<!--          <el-select style="width: 100%" v-model="project" value-key="id" filterable placeholder="请输入您想搜索的项目">-->
<!--            <el-option-->
<!--              v-for="item in projectList"-->
<!--              :key="item.id"-->
<!--              :label="item.customerName"-->
<!--              :value="item">-->
<!--              {{ item.customerName }}-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
      <div slot="footer" class="dialog-footer" style="overflow:auto; text-align: right">
        <el-button @click="changeCusDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { logout, resetPassword, queryBind, makeQrcode, unBind, getCusName, oToc } from '@/api/user'
import { getCount } from '@/api/notice'
import EventBus from '@/api/eventbus.js'
import notice from '@/components/notice'
import exportList from '@/components/exportList'
import { getTaskByCid,delectTaskFile } from '@/api/export'
import { getIsTrial, getToken, removeToken } from '@/utils/auth'
import { apiBaseURL } from "@/utils/request"
import {isHaveSwitchPermission, listCanSwitchCustomers, projectswitching} from "@/api/settings/project";
import eventHub from "@/utils/eventHub";
export default {
  components: {
    notice,
    exportList
  },
  computed: mapGetters(['name']),
  data () {
    var validateNewPass = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        callback(new Error('两次密码输入不一致!'));
      } else {
        callback()
      }
    }
    return {
      isTrial: true,
      showAvatorMenu: false,
      showNotice:false,
      showExport:false,
      count: 0,
      tableNum:0,
      currentPage: 1,
      size: 10,
      loading:false,
      tableData:[],
      form: {
        password: '',
        newPassword: '',
        checkPassword: ''
      },
      dialogVisible: false,
      bindWechatDialogVisible: false,
      showQRLoading: true,
      isBinded: false,
      bindUrl: '',
      dropStyle: {
        display: 'none'
      },
      rules: {
        password: [
          { required:true, message: '请输入原始密码', trigger: 'blur' }
        ],
        newPassword: [
          { required:true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码长度不低于6位', trigger: 'blur' }
        ],
        checkPassword: [
          { required:true, message: '请再次填写确认', trigger: 'blur' },
          { validator: validateNewPass, trigger: 'blur' }
        ]
      },
      domain: 'localhost',
      cusName: '',
      isEnable: '',
      changeCusDialogVisible: false,
      cusform: {
        project: '',
        cId: 0
      },
      project: {},
      projectList: [],
      goToClientLoading: false
    }
  },
  mounted () {
    this.isTrial = getIsTrial()
    this.domain = document.domain
    this.getCount()
    this.getCusName()
    this.permission()
    var _this = this
    this.project.id = JSON.parse(sessionStorage.getItem('store')).app.project.id
    EventBus.$on('taskCenterDialog', (result) => {
      if (result === 'open') {
        _this.showExportDialog()
      }
    })
  },
  destroyed () {
    EventBus.$off('taskCenterDialog')
  },
  methods: {
    async goToClient () {
      localStorage.removeItem('ct_c_token')
      localStorage.removeItem('ct_c_username')
      this.goToClientLoading = true
      const res = await oToc()
      this.goToClientLoading = false
      if (res.code === 0) {
        let cToken = res.msg.split('#')[1]
        let cUserName = res.msg.split('#')[0]
        window.open('http://yq.congting.com/client/#/redirect/' + cToken + '/' + cUserName, '_target')
      } else {
        this.$message.error(res.msg)
      }
    },
    async getCusName() {
      const res = await getCusName()
      this.cusName = res.msg
    },
    noticeClose(){
      this.showNotice = false
    },
    exportClose(){
      this.showExport = false
    },
    showMenu () {
      this.dropStyle.display = 'block'
      this.showAvatorMenu = true
    },
    resetPass () {
      this.dropStyle.display = 'none'
      this.showAvatorMenu = false
      this.dialogVisible = true
    },
    showExportDialog(){
      this.showExport = true
      this.currentPage = 1
      this.size = 10
      this.getList()
    },
    async getList(){
      const res = await getTaskByCid({ current: this.currentPage, pageSize: this.size })
      this.tableData = res.data
      this.tableNum = res.count
    },
    handleFun(id){
      let token = getToken()
      let down = document.createElement('a')
      down.href = apiBaseURL + '/downloadTask/downloadFile?&token=' + token + '&id='+id
      down.click()
    },
    async deleteFun(id){
      var res = await delectTaskFile({id:id})
      if(res.code == 0){
        this.$message.success('删除成功')
        this.getList()
      }
    },
    sizeChange: async function (i) {
      // this.loading = true
      this.currentPage = 1
      this.size = i
      this.getList()
    },
    currentChange: async function (i) {
      // this.loading = true
      this.currentPage = i
      this.getList()
    },
    async getCount () {
      var res = await getCount()
      if(res.code == 0){
        this.count = res.data
      }
    },
    logout () {
      this.dropStyle.display = 'none'
      this.showAvatorMenu = false
      this.$confirm('是否要退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        localStorage.removeItem('customType')
        window.localStorage.setItem("logout",this.$store.state.user.name)
        const res = await logout()
        this.$router.push('/login')
        sessionStorage.removeItem('curr_custom')
        // localStorage.clear()
        // sessionStorage.clear()
        removeToken()
        this.$store.dispatch('app/setUrl', '')
      }).catch(() => {})
    },
    cancle () {
      this.$refs['form'].resetFields()
      this.dialogVisible = false
    },
    onSubmit () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const res = await resetPassword({ password: this.form.password, newPassword: this.form.newPassword })
          console.log(res)
          if ( res.code === 0 ) {
            this.$refs['form'].resetFields()
            this.dialogVisible = false
            this.$alert('密码修改成功，请重新登录', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.$router.push('/login')
              }
            });
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false;
        }
      })
    },
    async bindWechat () {
      this.showQRLoading = true
      this.bindWechatDialogVisible = true
      const res = await queryBind()
      if (res.code === 0) {
        this.isBinded = false
        const qrRes = await makeQrcode()
        if (qrRes.code === 0) {
          this.showQRLoading = false
          this.bindUrl = qrRes.data
        } else {
          this.showQRLoading = false
          this.$message.error('二维码生成失败')
        }
      } else {
        this.isBinded = true
        this.showQRLoading = false
        // this.bindWechatDialogVisible = false
        this.$message.warning('该账号已绑定过微信账号')
      }
    },
    async doUnBindWechat () {
      this.showQRLoading = true
      const res = await unBind()
      if (res.code === 0) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
      this.bindWechat()
    },
    async permission () {
      const res = await isHaveSwitchPermission()
      this.isEnable = res.data.permission
      if (res.data.customerName) {
        this.projectName = res.data.customerName
      }
    },
    async listAllCus () {
      const res = await listCanSwitchCustomers()
      res.data.forEach((item,index)=>{
        if(item.id === this.$route.params.id){
          this.projectName = item.customerName
        }
      })
      this.projectList = res.data
    },
    changeProgect () {
      this.listAllCus()
      var classes = document.getElementsByClassName('sw-project')
      for (var i = 0; i < classes.length; i++) {
        classes[i].classList.remove('switch-selected-project')
      }
      this.changeCusDialogVisible = true
    },
    clickSwitchProject (dom, item) {
      var classes = document.getElementsByClassName('sw-project')
      for (var i = 0; i < classes.length; i++) {
        classes[i].classList.remove('switch-selected-project')
      }
      console.log(dom.currentTarget)
      console.log(item)
      dom.currentTarget.classList.add('switch-selected-project')
      this.project.name = item.customerName
      this.project.id = item.id
    },
    confirm () {
      if (!this.project.id || !this.project.name) {
        this.$message.warning('请先选择项目再进行确认')
        return
      }
      this.projectName = this.project.name
      this.changeCusDialogVisible = false
      for (var i = 0; i < this.projectList.length; i++) {
        if (this.projectList[i].id === this.project.id + '') {
          this.projectName = this.projectList[i].customerName
          break
        }
      }
      this.cusform.cId = this.project.id
      this.switchProject()
    },
    async switchProject () {
      const res = await projectswitching(this.cusform)
      if (res.code === 0) {
        this.$message.success('项目切换成功，页面即将自动刷新')
        this.cusName = this.projectName
        this.$store.dispatch('app/setProject', this.project)
        eventHub.$emit('cid', this.cusform.cId)
        localStorage.removeItem(localStorage.getItem('ct_account') + 'doneFirstGuide')
        localStorage.removeItem(localStorage.getItem('ct_account') + 'setAlwaysShowMS')
        sessionStorage.removeItem(localStorage.getItem('ct_account') + 'currentMonitor')
        if (getIsTrial() && !localStorage.getItem(localStorage.getItem('ct_account') + 'doneFirstGuide')) {
          this.$router.push('/monitor/list')
        } else {
          this.$router.push('/')
        }
        setTimeout(function () {
          location.reload()
        }, 100)
      } else {
        this.$message.error('抱歉，项目切换失败')
      }
    }
  }
}
</script>
<style scoped>
  .el-badge /deep/ .el-badge__content{
    top: 18px;
    right: 20px;
    padding: 0 5px;
  }
</style>
<style lang="scss">
.go-to-client .el-loading-mask {
  position: absolute;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.6);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  border-radius: 8px;
}
.header-wrap {
  width: 100%;
  height: 66px;
  padding: 0 20px;
  background: #3A404E;
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: relative;
  .logo {
    height: 45px;
  }
  .header-right {
    line-height: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .avatar {
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      background-color: #1976D2;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .dropdown-menu {
    width: 120px;
    text-align: left;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #ccc;
    //box-shadow: 2px 2px 1px rgba(0,0,0,.3);
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 5px 10px;
    bottom: -160px;
    right: 2px;
    z-index: 999;
    border-radius: 5px;
    //border-radius: 0 0 10px 10px;
    li{
      width: 100%;
      color: #000;
      height: 40px;
      line-height: 40px;
      padding-left: 10px;
      cursor: pointer;
    }
    li:active{
      background-color: #eee;
    }
    li:hover{
      background-color: #3372C7;
      color: #FFF;
      border-radius: 5px;
    }
  }
}
 .operation{
   margin: 10px;
    .icons{
      div{
        //display: inline-block;
        //width: 40px;
        //height: 60px;
        //line-height: 66px;
        text-align: center;
        // color: #999aaa;
        &:hover{
          //background-color: #3f4a5a;
        }
        .iconfont{
          font-size: 20px;
          color: #eee;
        }
      }
    }
  }
</style>
<style>
.switch-selected-project {
  color: #2D64B3;
}
.header-icon {
  padding: 0 10px;
}
.header-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}
</style>
