import axios from 'axios'
import router from '@/router'
import { getToken } from '@/utils/auth'
import fa from "element-ui/src/locale/lang/fa";

export const apiBaseURL = 'https://monitor.tiansongdata.com/oapi'
// export const apiBaseURL = 'http://127.0.0.1:8081/operation-api'

// create an axios instance
const service = axios.create({
  // : process.env.VUE_APP_BASE_API
  baseURL: apiBaseURL
})
// request interceptor
service.interceptors.request.use(
  config => {
    // axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // do something before request is sent
    if (getToken()) {
      config.headers['token'] = getToken()
    } else {
      router.push('/login')
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.data.code === 0 || response.data.code === 200) {
      const res = response.data
      // console.log('响应结果(response.data):' + res)
      // console.log('响应消息(response.msg):' + response.msg)
      return res
    } else if (response.data.code === 401) {
      // 超时，重新登陆
      router.push('/login')
    } else if (response.data.code === 403) {
      // 超时，重新登陆
      console.log('无权限')
      router.push('/noPermission')
      // this.$message.error('您暂无权限')
    } else {
      const res = response.data
      return res
    }
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

export default service
