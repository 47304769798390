import { getNav } from '@/api/layout'
import router from '@/router'
import message from 'element-ui/packages/message'
const state = {
  navList: [],
  navMap: [],
  project: {},
  urlPath: ''
}

const mutations = {
  INIT_NAV_LIST: (state, payload) => {
    state.navList = payload
  },
  SET_NAV_LIST: (state, payload) => {
    state.navList = payload
  },
  SET_NAV_MAP: (state, payload) => {
    state.navMap = payload
  },
  changeProject (state, value) {
    state.project = value
  },
  changeUrl (state, value) {
    state.urlPath = value
  }
}

const actions = {
  // user login
  async initNavList ({ commit }) {
    try {
      const res = await getNav()
      if (res.code === 500) {
        message.warning('当前登录状态异常，请重新登录')
        router.push('/login')
      }
      var wisdomMenu = {
        'id': '99',
        'pid': '0',
        'name': '智库',
        'url': '/wisdomLib',
        'type': 0,
        'icon': 'menu-icon fa fa-bar-chart-o',
        'permissions': 'stat:index',
        'sort': 7,
        'createDate': '2019-05-09 23:28:09',
        'parentName': null,
        'tId': null,
        'href': null,
        'menuname': null,
        'menuid': null,
        'pitchOn': null,
        'lastSearchDate': null,
        'queryString': null,
        'roleId': null
      }
      // res.data.splice(27, 0, wisdomMenu)
      // 筛选出 id >= 100 的对象
      let filteredItems = res.data.filter(item => item.id >= 100)

      // 从原数组中移除 id >= 100 的对象
      res.data = res.data.filter(item => item.id < 100)

      // 将筛选出的对象插入到第27位
      res.data.splice(20, 0, ...filteredItems)
      // console.log(res)
      commit('INIT_NAV_LIST', res.data)
      const [tree, map] = listToTree(res.data)
      commit('SET_NAV_LIST', tree)
      commit('SET_NAV_MAP', map)

      sessionStorage.setItem('navMap',JSON.stringify(map))
    } catch (err) {
      console.log(err)
    }
  },
  setNavList ({ commit }, payload) {
    commit('SET_NAV_LIST', payload)
  },
  setNavMap ({ commit }, payload) {
    commit('SET_NAV_MAP', payload)
  },
  setProject (context, value) {
    context.commit('changeProject', value)
  },
  setUrl (context, value) {
    context.commit('changeUrl', value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

export function listToTree (list) {
  var map = {}
  var node
  var tree = []
  var i
  for (i = 0; i < list.length; i++) {
    list[i].children = []
    map[list[i].id] = list[i]
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node.pid !== '0') {
      map[node.pid].children.push(node)
    } else {
      tree.push(node)
    }
  }
  return [tree, map]
}
